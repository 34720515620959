<div *ngIf="selectField" class="selectCourse"> 
    <div class="labels">
        <label>Select the Golf Course</label>
        <mat-form-field>
            <mat-label>Choose an option</mat-label>
            <mat-select >
              <mat-option *ngFor="let data of courses" value="option1">{{data.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        <div class="submit" (click)="changeRoute()" >
            Submit
        </div>
    </div>
  
</div>
<div class="bgContainer" *ngIf="isGamePlay" [style.background]="'url(' + questions[selectedQuestion].img + ') center / contain no-repeat'">

    <div class="questionContainer">
        <div class="question">
            <label>{{questions[selectedQuestion].question1}}</label>
            <input type="text">
        </div>
        <div class="question">
          <label>{{questions[selectedQuestion].question2}}</label>
          <input type="text">
      </div>
      <div class="nxtQuestion" (click)="nextQuestion()">
        <img style="float: right; cursor: pointer;" src="../assets/nextbtn.svg" >
      </div>
    </div>
  </div>