<div class="loginContainer">
    <img src="../../../assets/golf_logo.svg"/>
    <div class="inputContainer">
        <div class="labels">
            <label>Email Id</label>
            <input type="email"/>
        </div>
        <div class="labels">
            <label>Password</label>
            <input type="password"/>
        </div>
        <div>
            <div class="submit" (click)="changeRoute()">
                Submit
            </div>
        </div>
    </div>
</div>