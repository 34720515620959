import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AppComponent } from './app.component';
import { GameComponent } from './components/game/game.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    component:LoginComponent
  },
  {
    path:'game',
    component:GameComponent
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule],
  providers:[{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
