import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {

  constructor() { }
  foods: any = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'},
  ];
  selectedCar: any;

  courses = [
      { id: 1, name: 'Cambridge Country Club' },
      { id: 2, name: 'Cambridge Golf & Country Club' },
      { id: 3, name: 'Cambridge Golf Club' },
      { id: 4, name: 'Cambridge Lakes Golf Course' },
      { id: 5, name: 'Cambridge Golf Club Inc.' },
      { id: 6, name: 'Cambridge Meridian Golf Club' },
      { id: 7, name: 'Hallmark Hotel Cambridge' },
  ];

  title = 'golf';
  selectField:any=true
  isGamePlay:any=false
  selectedQuestion:any=0
  questions:any[]=[
    {
      question1:"What type of shot are you willing to play?",
      question2:"At what speed are you willing to play?",
      img:'../assets/1.JPEG'
    },
    {
      question1:"What type of strategy are you going to approach?",
      question2:"How many shots have you estimated to complete the game?",
      img:'../assets/2.JPEG'

    },
    {
      question1:"Which ball brand are you going to use?",
      question2:"What are the most convenient shots for this game?",
      img:'../assets/3.JPEG'

    },
    {
      question1:"What type of shot are you willing to play?",
      question2:"At what speed are you willing to play?",
      img:'../assets/4.JPEG'

    },
    {
      question1:"What type of strategy are you going to approach?",
      question2:"How many shots have you estimated to complete the game?",
      img:'../assets/5.JPEG'
    },
    {
      question1:"Which ball brand are you going to use?",
      question2:"What are the most convenient shots for this game?",
      img:'../assets/6.JPEG'
    },
    {
      question1:"What type of shot are you willing to play?",
      question2:"At what speed are you willing to play?",
      img:'../assets/7.JPEG'
    },
    {
      question1:"What type of strategy are you going to approach?",
      question2:"How many shots have you estimated to complete the game?",
      img:'../assets/8.JPEG'

    },
    {
      question1:"Which ball brand are you going to use?",
      question2:"What are the most convenient shots for this game?",
      img:'../assets/9.JPEG'

    },
    {
      question1:"What type of shot are you willing to play?",
      question2:"At what speed are you willing to play?",
      img:'../assets/10.JPEG'

    },
    {
      question1:"What type of strategy are you going to approach?",
      question2:"How many shots have you estimated to complete the game?",
      img:'../assets/11.JPEG'
    },
    {
      question1:"Which ball brand are you going to use?",
      question2:"What are the most convenient shots for this game?",
      img:'../assets/12.JPEG'
    },
  ]

  nextQuestion(){
    if(this.selectedQuestion+1<=this.questions.length)
      this.selectedQuestion +=1
  }

  ngOnInit(): void {
      
  }
  changeRoute(){
    this.isGamePlay = true
    this.selectField = false
  }
}
